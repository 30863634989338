import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const messages = {
    'haowan': require('./i18n.haowan'),
    'hongdou': require('./i18n.hongdou'),
};
//@ts-ignore
setTimeout(() => {
    console.log("游戏类型", GAME.type, i18n("account.b"));
}, 2000);
export default new VueI18n({ locale: GAME.type, messages });
