<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "App",
  beforeCreate() {
    window["i18n"] = Vue.prototype["i18n"] = (key) => {
      let values = [];
      let len = arguments.length - 1;
      while (len-- > 0) values[len] = arguments[len + 1];
      let i18n = this.$i18n;
      return i18n._t.apply(
        i18n,
        [key, i18n.locale, i18n._getMessages(), this].concat(values)
      );
    };
  },
};

function fontSize() {
  let view_width = document
    .getElementsByTagName("html")[0]
    .getBoundingClientRect().width;
  let _html = document.getElementsByTagName("html")[0];
  view_width > 768
    ? (_html.style.fontSize = (100 * 768) / 375 + "px")
    : (_html.style.fontSize = (view_width * 100) / 375 + "px");
}
fontSize();
window.onresize = function () {
  fontSize();
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  color: #000;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, SimSun, sans-serif;

  font-size: 14px !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

html {
  height: 0 !important;
}

.area-marks {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}

.self-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 隐藏进度条 */
::-webkit-scrollbar {
  display: none;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
