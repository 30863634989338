<template>
  <div
    v-show="isShow"
    click="function(event){event.stopPropagation();}"
    class="loading-layout"
    :class="{ 'show-bg': false }"
  >
    <div class="loader">
      <div class="dot nth-child-1"></div>
      <div class="dot nth-child-2"></div>
      <div class="dot nth-child-3"></div>
      <div class="dot nth-child-4"></div>
      <div class="dot nth-child-5"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}

.loading-layout {
  width: 100%;
  height: 100vh;
  position: fixed;
}
.show-bg {
  background: rgba(0, 0, 0, 0.1);
}

.dot {
  width: 16px;
  height: 16px;
  background: #21e9ba;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
  margin: 0 5px;
}

.nth-child-1 {
  animation-delay: 0.1s;
}

.nth-child-2 {
  animation-delay: 0.2s;
}

.nth-child-3 {
  animation-delay: 0.3s;
}

.nth-child-4 {
  animation-delay: 0.4s;
}

.nth-child-5 {
  animation-delay: 0.5s;
}

@keyframes slide {
  0% {
    transform: scale(1);
    background: #21e9ba;
  }

  50% {
    opacity: 0.3;
    transform: scale(1.5);
    background: #9b16ad;
  }

  100% {
    transform: scale(1);
    background: #21e9ba;
  }
}
</style>

<script>
export default {
  data() {
    return {
      isShow: false,
      showBg: false,
    };
  },

  methods: {},
};
</script>
