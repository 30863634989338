import ToastIndex from './ToastIndex.vue';

const Toast = {};

// 注册Toast
Toast.install = (Vue) => {
    // 生成一个Vue的子类
    const toastConstructor = Vue.extend(ToastIndex);
    // 生成一个子类的实例
    const instance = new toastConstructor();

    // 将这个实例挂载在我创建的div上
    // 加入全局挂载内部
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);

    //调用方法：this.$toast('======')
    window.$toast = Vue.prototype.$toast = (text, duration = 1400) => {
        instance.text = text;
        instance.isToast = true;
        clearTimeout(instance.$timer);
        instance.$timer = setTimeout(() => {
            instance.isToast = false;
        }, duration);
    };
};

export default Toast;
