//@ts-check
import Vue from 'vue';
// 兼容当前页面弹出多个弹框问题
let lastId = 0;
let styles = [];
const preventMove = Vue.directive('preventMove', {
    inserted(el, binding) {
        let app = document.getElementById('app');
        styles[lastId] = { overflow: app.style.overflow, position: app.style.position };
        app.style.overflow = 'hidden';
        app.style.position = 'fixed';
        lastId++;
    },
    unbind(el, binding) {
        lastId--;
        let app = document.getElementById('app');
        app.style.overflow = styles[lastId] ? styles[lastId].overflow : '' || '';
        app.style.position = styles[lastId] ? styles[lastId].position : '' || '';
        if (lastId <= 0) {
            styles.length = 0;
            lastId = 0;
        }
    }
});

export { preventMove };