// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router/routers'
import Toast from '../src/components/toast/Toast';
import Dialog from "../src/components/dialog/Dialog";
import VueClipboard from 'vue-clipboard2';
import Loading from "../src/components/loading/Loading";
import i18n from "../src/common/i18n/i18n.index";
import VueSwiper from 'vue-awesome-swiper'



/** 全局路径值 */
window.dialog = Dialog;
window.loading = Loading;

require("../src/common/directives/D_PreventMove");

Vue.config.productionTip = false
Vue.use(Toast);
Vue.use(Loading);
Vue.use(VueClipboard);
Vue.use(VueSwiper);

/* eslint-disable no-new */

new Vue({
    el: '#app',
    i18n,
    router,
    render: h => h(App)
}).$mount("#app")

