<template>
  <transition name="toast">
    <div class="toast-wrap" v-show="isToast">{{text}}</div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      isToast: false,
      text: ""
    };
  }
};
</script>
<style scoped lang="less">
.toast-wrap {
  position: fixed;
  z-index: 2010;
  top: 40%;
  left: 50%;
  width: auto;
  min-width: 1rem;
  max-width: 3rem;
  padding: 0.1rem 0.14rem;
  border-radius: 0.08rem;
  font-size: 0.14rem;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 20, 0.7);
  transform: translate(-50%, -50%);
}

.toast-enter-active,
.toast-leave-active {
  transition: 0.3s ease-out;
}

.toast-enter {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
}

.toast-leave-to {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.6);
}
</style>
