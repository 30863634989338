import Vue from 'vue';
let vueComponents = new Map();

export class _dialog {
    /**
     * 打开对话框
     * @param {*} vueComponentOptions
     * @param {*} props
     * @param {Function} onClose //关闭面板时的回调 用于知道[打开的面板关闭状态]及[添加其优先级的控制权]
     * 限制两秒执行一次
     */
    showOpenDialog(vueComponentOptions, props, onClose) {
        if (!vueComponents.get(vueComponentOptions)) {
            let modal = document.getElementById("modal");
            let VueConstructor = Vue.extend(vueComponentOptions);
            let inst = new VueConstructor({ propsData: props });
            inst.$mount();
            modal.appendChild(inst.$el);
            inst['__vueComponentOptions__'] = vueComponentOptions;
            inst['__onClose__'] = onClose;
            vueComponents.set(vueComponentOptions, inst);
            return inst;
        } else {
            console.error('当前弹框已存在！！！', vueComponentOptions);
            return vueComponents.get(vueComponentOptions);
        }
    }


    /**
     * 关闭对话框
     * @param {*} vueinst
     * @param {{popId:number,closeState:1|2,nextPopId:number,propsData:object}} data 非必传（队列弹框组时使用，若不是弹框组，可以不用传，弹框组时必传）。
     *  popId 为产品定义的弹窗id，
     *  closeState 关闭方式 1 点击关闭按钮关闭 2 点击其它按钮关闭,
     *  nextPopId 弹框组的下一个要弹的弹框id
     *  propsData 传递给下个页面的数据
     */
    closeOpenDialog(vueinst, data) {
        // oldTime = -100000;
        return new Promise((c, e) => {
            if (!vueinst) {
                console.warn("要关闭的vue实例不存在！");
                e("要关闭的vue实例不存在！");
                return;
            }
            if (vueinst.$el) {
                if (vueinst.$el.parentElement) {
                    vueinst.$el.parentElement.removeChild(vueinst.$el);
                    vueinst.$destroy();
                }
            }

            if (vueinst) {
                vueinst['__onClose__'] && vueinst['__onClose__'](data || {});
                if (vueinst['__vueComponentOptions__']) {
                    vueComponents.delete(vueinst['__vueComponentOptions__']);
                    vueinst['__vueComponentOptions__'] = undefined;
                }
            }

            setTimeout(() => {
                c();
            }, 0);
        });
    }

    /**
     * 关闭所有打开的弹窗
     * @memberof _dialog
     */
    closeAllDialog() {
        return new Promise((c, e) => {
            try {
                let $modals = Array.prototype.slice.call(document.querySelector('#modal').children);
                if ($modals.length) {
                    $modals.forEach(async el => {
                        // @ts-ignore
                        await this.closeOpenDialog(el['__vue__']);
                    });
                }
            } catch (error) {
                console.warn(error);
                e("要关闭的vue实例不存在！");
            }
            c();
        });
    }
}

export default new _dialog();