import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);

const routes = [
    {
        path: "/recharge",
        component: () => import("../modules/accout/vue/AccoutRecharge.vue"),
        name: "充值中心"
    },
    {
        path: "/privacyAgreement",
        component: () => import("../modules/office/vue/OfficePrivacyAgreementPage.vue"),
        name: "隐私协议"
    },
    {
        path: "/userAgreement",
        component: () => import("../modules/office/vue/OfficeUserAgreementPage.vue"),
        name: "用户协议"
    },
    {
        path: "*",
        component: () => import("../modules/office/vue/OfficeIndex.vue"),
        name: "手心玩官网"
    }
];

const router = new Router({
    mode: 'history',
    routes,
    base: "web"
});

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.name) {
        document.title = to.name;
    }
    next()
})


export default router;


