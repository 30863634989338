import LoadingIndex from './LoadingIndex.vue';

const Loading = {};

Loading.install = (Vue) => {
    // 生成一个Vue的子类
    const LoadingConstructor = Vue.extend(LoadingIndex);
    // 生成一个子类的实例
    const instance = new LoadingConstructor();

    // 将这个实例挂载在我创建的div上
    // 加入全局挂载内部
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);

    Loading.show = (showBg, duration) => {
        instance.isShow = true;
        instance.showBg = showBg;
        clearTimeout(instance.$timer);
        if (typeof duration == "number") {
            instance.$timer = setTimeout(() => {
                instance.isShow = false;
                instance.showBg = false;
            }, duration);
        }

    };
    Loading.hide = () => {
        instance.isShow = false;
        instance.showBg = false;
        clearTimeout(instance.$timer);
    }
};

export default Loading;